@import "modules";
@import "baseLayout";

.page {
  h2 {
    margin: 24px 16px 8px;
  }
}

.billboardCarousel {
  text-align: center;
  height: 80vh;
  background-color: $secondaryColor;
  overflow: hidden;
}

.slideItem {
  height: 80vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sectionInnerWrap {
  padding: 16px;
}