@import "modules";
@import "baseLayout";

.page {

}

.sideMenu {
  display: none;
  @include mediaQuery(md) {
    display: block;
    width: 250px;
    height: 100vh;
  }
}

.content {
  min-height: 100vh;
}


// Nursery
.thumbnail {
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.thumbnailEditButtonWrap {
  position: absolute;
  bottom: 24px;
  right: 32px;
}
.settingContent {
  padding: 24px 32px;
  background-color: $white;
}