$white: white;
$lightGray: #f8f8f8;
$mediumGray: #999;
$darkGray: #333;
$black: black;

$primaryColor: #BFC795;
$secondaryColor: #fff;
$subContentBgColor: #fff;
$lineColor: #ddd;
$bodyBackgroundColor: rgba(0, 0, 0, 0.65);
$contentBackgroundColor: white;