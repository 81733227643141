@import "modules";

.scrollZone {
  max-height: 60vh;
  overflow-y: scroll;
}

.contentSection {
  width: 100%;
  margin: 0;
  padding: 24px 0;
}

.sectionInnerWrap {
  margin: 0 auto;

  @include mediaQuery(md) {
    margin: 0 32px;
  }

  @include mediaQuery(lg) {
    width: $max-page-content-width;
    margin: 0 auto;
  }
}