@import "modules";
@import "baseLayout";


.container {
  padding: 24px 32px;
}

.content {

}

.date {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  padding: 0 8px;
}

.page {
  background-color: $white;
  min-height: 100vh;
  padding: 0 24px;
}

.dateSelector {

}

.sidebar {
  min-height: 100vh;
  height: 100%;
  background-color: $white;
  padding: 16px;
}

.flex {
  display: flex;
}

.flexGridBox {
  display: flex;
  flex-wrap: wrap;
}

.reportCard {
  padding-bottom: 16px;
  border-bottom: 1px solid $lineColor;
}