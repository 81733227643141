@import "modules";
@import "baseLayout";

.page {
}

.tab {
  >:first-child {
    margin: 0;
  }
}

.tabPane {
  background-color: $white;
  padding: 32px 24px;
}

.paneContent {
  min-height: 80vh;
  padding-bottom: 32px;
}

.childrenSidebar {
  background-color: $white;

  img {
    margin-right: 8px;
  }
}

.childMenuItem {
  height: 70px;
}

.sidebarSectionHeader {
  background-color: $lightGray;
  padding: 16px;
}

// 基本情報
.basicInfoSection {
  width: 100%;
  padding: 16px 0 32px;
  border-bottom: 1px solid $lineColor;
}

// 身体測定
.physicalFormWrap {
  padding: 16px 0 32px;
  border-bottom: 1px solid $lineColor;
}

.physicalTableWrap {
  padding: 16px 0 32px;
}