@import "modules";

.contentSection {
  width: 100%;
  margin: 0;
  padding: 24px 0;
}

.sectionInnerWrap {
  margin: 0 auto;

  @include mediaQuery(md) {
    margin: 0 32px;
  }

  @include mediaQuery(lg) {
    width: $max-page-content-width - $opened-sidebar-width;
    margin: 0 auto;
  }
}

.subContent {
  background-color: $subContentBgColor;
  padding: 24px 16px;
}

.overflowXScroll {
  overflow-x: scroll;
}

.overflowYScroll {
  overflow-y: scroll;
}

// padding
.paddingSmall {
  padding: 16px;
}

.paddingMedium {
  padding: 24px;
}

.paddingLarge {
  padding: 32px;
}

.verticalPaddingSmall {
  padding: 16px 0;
}

.paddingTopMedium {
  padding-top: 24px;
}

.paddingBottomSmall {
  padding-bottom: 16px;
}